@width: 640px;
@minHeight:500px;
html,body{
    height:100%;
}
.main {
    max-width: @width;
    min-height: 500px;
    margin: 0 auto;
    position: relative;
    height:100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 10px 0;
}
.article {
    color: #4d4d4d;
}

.wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 20;
    -webkit-overflow-scrolling:touch;
    top:0;
    left:0;
    overflow: hidden;
}
.wrapper a {
    display: block;
}

.occupy {
    width: 100%;
    height: 40px;
    @media screen and (min-height: @minHeight) {
        font-size: 20px;
        height: 9vh;
        line-height: 9vh;
    }
}

.occupy1 {
    width: 100%;
    height: 40px;
    @media screen and (min-height: @minHeight) {
        font-size: 20px;
        height: 8vh;
        line-height: 8vh;
    }
}

.article .nav-header {
    width: 100%;
    max-width: @width;
    font-size: 20px;
    background-color: #f4f5f6;
    border-bottom-left-radius:5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    position: fixed;
    top: 0;
    z-index: 999;
    color: #eb3d00;
    height: 40px;
    box-shadow: 0px 10px 10px -10px #ccc;
    @media screen and (min-height: @minHeight) {
        font-size: 20px;
        height: 8vh;
        line-height: 8vh;
    }
}

.article .nav-header .logo {
    display: block;
    font-weight: 700;
    font-size:20px;
    height: 40px;
    width: 100%;
    line-height: 40px;
    @media screen and (min-height: @minHeight) {
        font-size: 20px;
        height: 8vh;
        line-height: 8vh;
    }
}
.article .nav-header svg {
    vertical-align: middle;
    position: absolute;
    fill: #eb3d00;
}
.article .nav-header .iconlef {
    top: 5px;
    left: 10px;
    width: 30px;
    height: 30px;
    @media screen and (min-height: @minHeight) {
        top: 2vh;
        left: 2vw;
        width: 4vh;
        height: 4vh;
        min-width: 30px;
        min-height: 30px;
    }
}
.article .nav-header .iconrig {
    top: 5px;
    right: 10px;
    width: 30px;
    height: 30px;
    @media screen and (min-height: @minHeight) {
        top: 2vh;
        right: 2vw;
        width: 4vh;
        height: 4vh;
        min-width: 30px;
        min-height: 30px;
    }
}

.article form{
    width: 100%;
    position: absolute;
    background-color: #FFF;
    z-index: 991;
    top:0;
    left:0;
}

.article .articleSearch {
    margin: 0 2vw;
    height: 6vh;
    min-height: 40px;
    border: 1px solid #eb3d00;
}
.article .articleSearch .searchcon {
    width: 78%;
    max-width: 430px;
    padding-left: 3vw;
    height: 100%;
    border: none;
    font-size: 18px;
}
.article .articleSearch .searchBot {
    width: 13%;
    height: 100%;
    font-size: 20px;
    line-height: 8vh;
    min-width: 40px;
    min-height: 24px;
    text-align: center;
    display: block;
    float: right;
}
.article .articleSearch .searchBot svg {
    fill: #eb3d00;
}
.article .articleSearch .searchBot .iconSearch {
    width: 60%;
    height: 60%;
    margin-top: 10%;
}

.article .articleItems {
    padding: 0 1vw;
}

.article .articleItems .noInfo {
    fill: #eb3d00;
    text-align: center;
    padding: 1vh 0 2vh 0;
    font-size: 16px;
}
.article .articleItems .articleItem {
    border-bottom: 1px solid #cccccc;
    padding: 1vh;
    margin-bottom: 2vh;
    max-width: @width;
    min-height: 60px;
}
.article .articleItems .articleItem .name {
    font-size: 20px;
    min-height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media screen and (min-height: 650px) {
        line-height: 26px;
    }
}
.article .articleItems .articleItem .time {
    color: #808080;
    text-align: right;
    font-size: 14px;
}
.article .articleItems .articleItem .content {
    position: relative;
    overflow: hidden;
    color: #808080;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin: 0.5vh 0;
    line-height: 3.5vh;
    min-height: 40px;
    @media screen and (min-height: 650px) {
        line-height: 24px;
    }
}

.article .articleItems .articleType1 .content {
    -webkit-line-clamp: 2;
}

.article .articleItems .articleType1 {
    clear: both;
    overflow: hidden;
}
.article .articleItems .articleType1 .lef {
    width: 65%;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
}
.article .articleItems .articleType1 .lef .title {
    overflow: hidden;
    line-height: 24px;
    font-size:18px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    @media screen and (min-height: 650px) {
        line-height: 3vh;
    }
}
.article .articleItems .articleType1 .lef .time {
    text-align: left;
}
.article .articleItems .articleType1 .rig {
    width: 35%;
    height: 23vw;
    max-height: 150px;
    border-radius: 5px;
    font-size:0;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}
.article .articleItems .articleType1 .rig img {
    width: 100%;
    height: 23vw;
    max-height: 150px;
}

.article .articleItems .articleType2 {
    overflow: hidden;
}
.article .articleItems .articleType2 .title {
    line-height: 4vh;
    margin-bottom: 1vh;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    @media screen and (max-height: 650px) {
        line-height: 24px;
    }
}

.articleDetail .artDetTitle {
    margin-bottom: 2vh;
    font-weight: bold;
    font-size: 28px;
    @media screen and (min-width: 1100px) {
        font-size: 20px;
    }
}

.article .articleItems .articleType2 .pic {
    width: 100%;
    height: 50vw;
    max-height: 300px;
    overflow: hidden;
    border-radius: 5px;
}
.article .articleItems .articleType2 .pic img {
    width: 100%;
    height: 50vw;
    max-height: 300px;
    display: block;
}

.article .articleItems .articleType4 .title {
    line-height: 4vh;
    margin-bottom: 1vh;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    @media screen and (max-height: 650px) {
        line-height: 24px;
    }
}

.article .articleItems .articleType4 .pic {
    width: 100%;
    height: 21.8vw;
    max-height: 150px;
    overflow: hidden;
    border-radius: 5px;
}
.article .articleItems .articleType4 .pic img {
    width: 100%;
    height: 21.8vw;
    max-height: 150px;
    display: block;
    float: left;
}

.article .articleItems .articleType3 {
    font-size: 20px;
}
.article .articleItems .articleType3 .pic {
    position: relative;
    height: 50vw;
    max-height: 300px;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
}
.article .articleItems .articleType3 .pic .title {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #ffffff;
    background-color: rgba(8, 8, 8, 0.3);
    width: 100%;
    padding: 0.5vh 1vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.article .articleItems .articleType3 .pic img {
    width: 100%;
    height: 50vw;
    max-height: 300px;
}